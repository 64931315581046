import {find, flatten, forEach, isNil} from 'lodash';
import {DictionaryEntryRequirement, FieldRequirement} from '../../../../../shared/model/dictionary-requirement.model';
import {NxFile} from '../../../../../shared/model/nx-file.model';
import {DictionaryHelper} from '../../../../../utils/dictionary-helper';
import {
  CustomerDataPhase
} from '../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import {parseSavedCheck} from '../shared/custom-checks/check-values-utils';
import {Address} from './addresses/address.model';
import {CustomFieldValue, CustomFieldValueTO} from './custom-fields/custom-field.model';
import {
  Applicant,
  CorporateDataFormFields, CorporateDataTO,
  EntryRequirementValue,
  IndividualDataFormFields,
  IndividualDataTO
} from './customer-data-form.model';
import {IdDocument} from './documents/id-document.model';
import {IncomeSource} from './income/income-source.model';
import {EntryRequirementHelper} from './shared/entry-requirement-helper';

const {getEnabledEntryRequirements} = DictionaryHelper;
const {filterRequiredOrProvidedRequirements, sortEntryRequirements} = EntryRequirementHelper;

export const CustomerDataFormHelper = {

  overwriteIndividualDataBySavedValues: (formFields: IndividualDataFormFields, savedData?: IndividualDataTO): void => {
    forEach(savedData, (value, key): void => {
      // update values only for fields corresponding with saved data
      if (formFields[key] && !isNil(value)) {
        formFields[key].value = value.toString();
      }
    });
  },
// TODO how to merge with above?
  overwriteCorporateDataBySavedValues: (formFields: CorporateDataFormFields, savedData?: CorporateDataTO): void => {
    forEach(savedData, (value, key): void => {
      // update values only for fields corresponding with saved data
      if (formFields[key] && !isNil(value)) {
        formFields[key].value = value.toString();
      }
    });
  },

  getIncomeSources: (config: CustomerDataPhase, borrower?: Applicant): IncomeSource[] =>
    getEnabledEntryRequirements(config?.incomeRequirement)
      .filter(entry => filterRequiredOrProvidedRequirements(entry, borrower?.incomeSources))
      .sort(sortEntryRequirements)
      .map(entry => {
        const {existingData, required, typeId} = getRequirementMappingParams(entry, borrower?.incomeSources);

        return existingData ? {...existingData, required} : {typeId, required} as IncomeSource;
      }),

  getIdDocuments: (config: CustomerDataPhase,
                   idDocuments: IdDocument[]): IdDocument[] =>
    getEnabledEntryRequirements(config?.idDocumentRequirement)
      .filter(entry => filterRequiredOrProvidedRequirements(entry, idDocuments))
      .sort(sortEntryRequirements)
      .map(entry => {
        const {existingData, required, typeId} = getRequirementMappingParams(entry, idDocuments);
        const scans: NxFile[] = [];

        return existingData ? {...existingData, typeId, required} : {typeId, scans, required} as IdDocument;
      }),

  getAddresses: (config: CustomerDataPhase, applicant?: Applicant): Address[] =>
    getEnabledEntryRequirements(config?.addressRequirement)
      .filter(entry => filterRequiredOrProvidedRequirements(entry, applicant?.addresses))
      .sort(sortEntryRequirements)
      .map(entry => {
        const {existingData, required, typeId} = getRequirementMappingParams(entry, applicant?.addresses);

        return existingData ? {...existingData, required} : {typeId, required} as Address;
      }),

  getCustomFields: (config: CustomerDataPhase,
                    customFieldValues?: CustomFieldValueTO[]): CustomFieldValue[] =>
    flatten(config?.dynamicFieldGroups.map(group => group.fields))
      .map(customField => {

        const {id, required, type} = customField;
        const savedValue = customFieldValues?.find(fieldValue => fieldValue.customFieldId === id)?.value;
        const value = parseSavedCheck(savedValue, type);

        return ({value, customFieldId: id, required, fieldType: type}) as CustomFieldValue;
      })
};

const getRequirementMappingParams = <T extends EntryRequirementValue>(entry: DictionaryEntryRequirement, data?: T[]):
  {existingData?: T, typeId: number, required: boolean} => {

  const existingData = find(data, address => address.typeId === entry.dictionaryEntryId);
  const typeId = entry.dictionaryEntryId;
  const required = entry.requirement === FieldRequirement.REQUIRED;

  return {existingData, typeId, required};
};
