import mapValues from 'lodash/mapValues';
import {FieldType} from '../../shared/model/field.model';
import {PhaseWithStaticFields} from '../../shared/model/phase.model';
import {IndividualDataFormFieldsConfiguration} from '../../shared/model/step-forms/individual-data-form.model';
import {StaticFieldsHelper} from './static-fields-helper';

const {getInitField, getInitFormFields} = StaticFieldsHelper;

export const INDIVIDUAL_DATA_FIELD_CODES = {
  // Personal data
  firstName: 'FIRST_NAME',
  lastName: 'LAST_NAME',
  middleName: 'MIDDLE_NAME',
  titleId: 'TITLE',
  nickname: 'NICKNAME',
  extension: 'EXTENSION',

  // Contact data
  email: 'EMAIL',
  mobileNumber: 'MOBILE_NUMBER',
  workNumber: 'WORK_NUMBER',
  landlineNumber: 'LANDLINE_NUMBER',

  // Demographics
  genderId: 'GENDER',
  civilStatusId: 'CIVIL_STATUS',
  birthDate: 'BIRTH_DATE',
  birthPlace: 'BIRTH_PLACE',
  residenceId: 'RESIDENCE',
  citizenshipId: 'CITIZENSHIP',
  birthCountryId: 'BIRTH_COUNTRY',

  // Relatives
  mothersFirstName: 'MOTHERS_FIRST_NAME',
  mothersMaidenName: 'MOTHERS_MAIDEN_NAME',
  mothersMiddleName: 'MOTHERS_MIDDLE_NAME',

  // Compliance
  vip: 'VIP',
  signature: 'SIGNATURE',
  pep: 'PEP',
  riskLevelId: 'RISK_LEVEL',
  dosri: 'DOSRI',
  dosriTypeId: 'DOSRI_TYPE',
  bankEmployee: 'BANK_EMPLOYEE',
  picture: 'PICTURE',
  relatedPartyTransaction: 'RELATED_PARTY_TRANSACTION'
};

export const getIndividualDataInitFields = (config: PhaseWithStaticFields): IndividualDataFormFieldsConfiguration => {

  const individualDataFields = mapValues(INDIVIDUAL_DATA_FIELD_CODES, getInitField) as IndividualDataFormFieldsConfiguration;

  return getInitFormFields(individualDataFields, config);
};
