import {FormikErrors} from 'formik/dist/types';
import {TFunction} from 'i18next';
import isEmpty from 'lodash/isEmpty';
import {AnySchema} from 'yup';
import {SetFieldValue} from '../../../shared/model/formik.model';

export const VALIDATION_INDICATOR_FIELD = 'validate';
export const REQUIRED_FIELD = 'required';

const ERRORS_PREFIX = 'COMMON.ERRORS';
export const getDefaultRequiredError = (t: TFunction): string => t(`${ERRORS_PREFIX}.REQUIRED_FIELD`);
export const getDefaultFieldLengthExceededError = (t: TFunction, max: number): string =>
  t(`${ERRORS_PREFIX}.EXCEEDED_LENGTH`, {max});
export const getFieldNegativeError = (t: TFunction): string => t(`${ERRORS_PREFIX}.NEGATIVE_VALUE`);
export const getTooSmallError = (t: TFunction, min: number): string => t(`${ERRORS_PREFIX}.MIN_VALUE`, {min});
export const getTooBigError = (t: TFunction, max: number): string => t(`${ERRORS_PREFIX}.MAX_VALUE`, {max});
export const getTermTooSmallError = (t: TFunction, min: number, calculated: number): string =>
  t(`${ERRORS_PREFIX}.TERM_TOO_SMALL`, {min, calculated});
export const getTermTooBigError = (t: TFunction, max: number, calculated: number): string =>
  t(`${ERRORS_PREFIX}.TERM_TOO_BIG`, {max, calculated});

/*
* To avoid 'cycling dependency' error in yup we have to provide pairs of field validations that depend on each other.
* For optional addresses, income sources and id documents we're validating optionally all input fields in object,
*/
export const dependencyKeysPairs = (keys: string[]): [string, string][] =>
  keys.map((value, index) => keys.slice(index + 1).map(key => [value, key])).flat() as [string, string][];

/*
*   '.when' rule checking VALIDATION_INDICATOR_FIELD have to be attached on the same level as VALIDATION_INDICATOR_FIELD
*   so for forms with different structure then current usage, this solution probably can't be used without changes
*/
export const getOptionalValidationSchema = (schema: (partialValidation?: boolean) => AnySchema): AnySchema =>
  schema(true).when(VALIDATION_INDICATOR_FIELD, {is: true, then: schema()});

export const getSchemaBasedOnRequiredField =
  (partialValidation: boolean, optionalSchema: AnySchema, requiredSchema: AnySchema): AnySchema =>
    partialValidation ? optionalSchema : optionalSchema.when(REQUIRED_FIELD, {is: true, then: requiredSchema});

export const
  validateAndSubmitForm =
  async <T>(values: T,
            validate: boolean,
            submitForm: () => Promise<void>,
            validateForm: (values?: unknown) => Promise<FormikErrors<T>>,
            setFieldValue: SetFieldValue,
            t: TFunction): Promise<void> => {

    setFieldValue(VALIDATION_INDICATOR_FIELD, validate);

    const errors = await validateForm({
      ...values,
      validate // pass here to make sure validation schema gets current data
    });

    return isEmpty(errors) ? submitForm() : Promise.reject(t(`${ERRORS_PREFIX}.INVALID_FORM`));
  };
