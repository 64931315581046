import {Category} from '../shared/model/category.model';
import {cloneDeep, isNil} from 'lodash';

export const addParentsToCategoryName = (initialCategories?: Category[], addFirstChildName = true): Category[] => {

  if (isNil(initialCategories)) {
    return [];
  }

  const categories = cloneDeep(initialCategories);

  const addParentName = (childIndex: number,  parentId?: number,  addFirstChildName = true): void => {
    if (!parentId) {
      return;
    }

    const parent = initialCategories.find(({id}) => id === parentId);

    if (!parent) {
      return;
    }

    const {name, parentCategoryId} = parent;
    categories[childIndex].name = addFirstChildName ? `${name} > ${categories[childIndex].name}` : name;

    addParentName(childIndex, parentCategoryId);
  }

  categories.forEach((category, index) =>
    addParentName(index, category.parentCategoryId, addFirstChildName));

  return categories;
}
