import dayjs, {Dayjs} from 'dayjs';
import {FileBasicData} from '../../shared/model/nx-file.model';

export interface CreateBatchPayload {
  name: string;
  fileId: number;
}

export interface BatchTO<T_DATE = string> {
  id: number;
  name: string;
  fileId: number;
  itemsCount: number;
  validItemsCount: number;
  invalidItemsCount: number;
  type: BatchType;
  status: BatchStatus;
  createdBy: string;
  createdOn: T_DATE;
  processedOn?: T_DATE;
  canceledBy?: string;
  canceledOn?: T_DATE;
  approvedBy?: string;
  approvedOn?: T_DATE;
  processingTime: number;
}

export type Batch = BatchTO<Dayjs>

export enum BatchStatus {
  CREATED = 'CREATED',
  CANCELED = 'CANCELED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  REVOKED = 'REVOKED',
  ERROR = 'ERROR'
}

export enum BatchType {
  PRE_APPROVED = 'PRE_APPROVED'
}

export interface BatchSearchCriteria {
  id?: number;
  name?: string;
  statuses?: BatchStatus[];
}

export const toBatch = (batchTO: BatchTO): Batch => {
  return {
    ...batchTO,
    createdOn: dayjs(batchTO.createdOn),
    processedOn: batchTO.processedOn ? dayjs(batchTO.processedOn) : undefined,
    canceledOn: batchTO.canceledOn ? dayjs(batchTO.canceledOn) : undefined,
    approvedOn: batchTO.approvedOn ? dayjs(batchTO.approvedOn) : undefined
  };
};

export interface BatchItemTO<T_DATE = string> {
  id: number;
  batchId: number;
  rownum: number;
  referenceNo: string;
  description?: string;
  status: BatchItemStatus;
  createdOn: T_DATE;
  processedOn?: T_DATE;
  data?: PreApprovedApplication;
  valid?: boolean;
  errors?: string[];
}

export interface PreApprovedApplication {
  applicationId?: number;
  processCode: string;
  principal: number;
  interestRate: number;
  customerNumber: string;
  paymentInterval: string;
  totalAmortizationNumber: number;
}

export type BatchItem = BatchItemTO<Dayjs>

export enum BatchItemStatus {
  CREATED = 'CREATED',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
  REVOKED = 'REVOKED'
}

export const toBatchItem = (batchItemTO: BatchItemTO): BatchItem => {
  return {
    ...batchItemTO,
    createdOn: dayjs(batchItemTO.createdOn),
    processedOn: batchItemTO.processedOn ? dayjs(batchItemTO.processedOn) : undefined
  };
};

export const toBatchItems = (batchItemTOs: BatchItemTO[]): BatchItem[] => {
  return batchItemTOs.map(toBatchItem);
};

export interface BatchItemSearchCriteria {
  referenceNo?: string;
  valid?: string;
  statuses: BatchItemStatus[];
}
