import {useContext, useState} from 'react';
import {PRINTS_URL} from '../../../../../../../constants/api-urls';
import usePost from '../../../../../../../shared/hooks/use-post.hook';
import HttpService from '../../../../../../../shared/http-service/http.service';
import {Print, PrintRequest, PrintType} from '../../../../../../../shared/model/print.model';
import {ReportStatus} from '../../../../../../../shared/model/report.model';
import {downloadFile} from '../../../../../../../utils/file-download-utils';
import {AppSnackbarContext} from '../../../../../../shared/app-snackbar-provider/AppSnackbarProvider';

interface ReturnProps {
  onPrint: () => Promise<void>,
  isGenerating: boolean
}

export default function usePrint(applicationId: number, phaseId: number, printType: PrintType): ReturnProps {

  const generatePrint = usePost<Print, PrintRequest>(PRINTS_URL);
  const {showErrorMessage} = useContext(AppSnackbarContext);
  const [isGenerating, setIsGenerating] = useState(false);

  const onPrint = async (): Promise<void> => {
    if (isGenerating) {
      return;
    }

    setIsGenerating(true);
    let print = await generatePrint({phaseId, applicationId, printType});

    const intervalId = setInterval(async () => {
      print = await HttpService.get<Print>(`${PRINTS_URL}/${print.id}`);

      if (print.status == ReportStatus.COMPLETED) {
        downloadFile(print.url, print.printType);
        clearInterval(intervalId);
        setIsGenerating(false);
      }

      if (print.status == ReportStatus.FAILED) {
        showErrorMessage('Failed to generate print');
        clearInterval(intervalId);
        setIsGenerating(false);
      }
    }, 1000);
  };

  return {onPrint, isGenerating};
}
