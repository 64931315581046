import mapValues from 'lodash/mapValues';
import React, {ReactElement, useMemo} from 'react';
import {DictionaryEntry} from '../../../../../../../shared/model/dictionary.model';
import {isAnyEnabled} from '../../../../../../../utils/empty-checks-utils';
import {SelectHelper} from '../../../../../../../utils/select-helper';
import {StringHelper} from '../../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../../utils/trans-helper';
import FormColumn from '../../../../../../shared/form-column/FormColumn';
import FormGroup from '../../../../../../shared/form-column/FormGroup';
import {CorporateDataFormFields} from '../../customer-data-form.model';
import {
  ApplicantDataDatePicker as DatePicker,
  ApplicantDataFileInput as FileInput,
  ApplicantDataInput as Input,
  ApplicantDataPhoneInput as PhoneInput,
  ApplicantDataSelect as Select
} from '../applicant-data-inputs/IndividualDataInputs';
import styles from '../ApplicantlData.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.CUSTOMER_DATA.APPLICANT_DATA');
const {mapToStringOptions} = SelectHelper;

interface Props {
  values: CorporateDataFormFields;
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.individualData.firstName.
   */
  formikFieldPath: string;
  entries: {
    businessType?: DictionaryEntry[];
    riskLevel?: DictionaryEntry[];
    dosriType?: DictionaryEntry[];
    registrationCountry?: DictionaryEntry[];
    conglomerate?: DictionaryEntry[];
    relatedPartyTransaction?: DictionaryEntry[];
  };
}

const checkOptionSelected = (option: string, options?: DictionaryEntry[], optionId?: string | null): boolean => {
  if (!optionId) {
    return false;
  }

  const corporationEntry = options?.filter(entry => entry.code === option);
  if (!corporationEntry || !corporationEntry[0]) {
    return false;
  }

  return optionId === corporationEntry[0].id.toString();
};

export default function CorporateData({values, formikFieldPath, entries}: Props): ReactElement {

  const {
    businessName, tradeName, businessTypeId, sourceOfFunds, placeOfIncorporation, registrationDate,
    registrationCountryId, email, phoneNumber, riskLevelId, dosri, dosriTypeId, picture, signature,
    relatedPartyTransactionId, registrationNumber, conglomerateId, relatedPartyTransactionDescription
  } = values;

  const options = mapValues(entries, entryArray => mapToStringOptions(entryArray));

  const fieldPath = `${formikFieldPath}.corporateData`;
  const getPath = (fieldName: string): string => `${fieldPath}.${fieldName}`;

  const corporationBusinessTypeSelected: boolean = useMemo(() =>
    checkOptionSelected('CORPORATION', entries.businessType, businessTypeId.value), [businessTypeId.value]);

  const rptOthersSelected = useMemo(() =>
      checkOptionSelected('OTHERS', entries.relatedPartyTransaction, relatedPartyTransactionId.value),
    [relatedPartyTransactionId.value]);

  return (
    <>
      <div className={styles.row}>
        <FormColumn>
          <FormGroup visible={isAnyEnabled([businessName, tradeName, businessTypeId, sourceOfFunds,
            placeOfIncorporation, registrationDate, registrationCountryId])}
                     header={<PrefixTrans>PERSONAL_DATA</PrefixTrans>}>
            <Input fieldKey={getPath('businessName')} value={businessName} />
            <Input fieldKey={getPath('tradeName')} value={tradeName} />
            <Select fieldKey={getPath('businessTypeId')} value={businessTypeId} options={options.businessType} />
            {corporationBusinessTypeSelected &&
              <Select fieldKey={getPath('conglomerateId')} value={conglomerateId} options={options.conglomerate} />
            }
            <Input fieldKey={getPath('sourceOfFunds')} value={sourceOfFunds} />
            <Input fieldKey={getPath('placeOfIncorporation')} value={placeOfIncorporation} />
            <Input fieldKey={getPath('registrationNumber')} value={registrationNumber} />
            <DatePicker fieldKey={getPath('registrationDate')} value={registrationDate} />
            <Select fieldKey={getPath('registrationCountryId')}
                    value={registrationCountryId}
                    options={options.registrationCountry} />
          </FormGroup>
          <FormGroup visible={isAnyEnabled([riskLevelId, dosri, picture, signature, relatedPartyTransactionId])}
                     header={<PrefixTrans>COMPLIANCE</PrefixTrans>}>
            <Select fieldKey={getPath('riskLevelId')} value={riskLevelId} options={options.riskLevel} />
            <Select fieldKey={getPath('dosri')} value={dosri} emptyOption={false} />
            {
              StringHelper.stringToBoolean(dosri.value) &&
              <Select fieldKey={getPath('dosriTypeId')} value={dosriTypeId} options={options.dosriType} />
            }
            <FileInput fieldKey={getPath('signature')} value={signature} />
            <FileInput fieldKey={getPath('picture')} value={picture} />
            <Select fieldKey={getPath('relatedPartyTransactionId')}
                    value={relatedPartyTransactionId}
                    options={options.relatedPartyTransaction} />
            {rptOthersSelected &&
                <Input fieldKey={getPath('relatedPartyTransactionDescription')}
                     value={relatedPartyTransactionDescription} />
            }
          </FormGroup>
        </FormColumn>
        <FormColumn>
          <FormGroup visible={isAnyEnabled([email, phoneNumber])}
                     header={<PrefixTrans>CONTACT_DATA</PrefixTrans>}>
            <Input fieldKey={getPath('email')} value={email} />
            <PhoneInput fieldKey={getPath('phoneNumber')} value={phoneNumber} />
          </FormGroup>
        </FormColumn>
      </div>
    </>
  );
}
