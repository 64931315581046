import {NxSelect, NxSelectOption} from '@nextbank/ui-components';
import isNil from 'lodash/isNil';
import React, {useContext, useEffect, useState} from 'react';
import {PROCESSES_URL} from '../../../../../constants/api-urls';
import {RoutePaths} from '../../../../../routes/routes.paths';
import useGet from '../../../../../shared/hooks/use-get.hook';
import {fromProcessTO, Process, ProcessTO, ProductStatus} from '../../../../../shared/model/process.model';
import {buildApplicationStepPath} from '../../../../../utils/loan-steps-utils';
import {useGuardedHistory} from '../../../../router/GuardedHistory';
import {LoanApplicationContext} from '../../LoanApplication';
import styles from './ChangeProductForm.module.scss';

const statusPublishedParam = new URLSearchParams(`status=${ProductStatus.PUBLISHED}`);

interface Props {
  processId: number,
  applicationId: string
}

export const ChangeProductForm = ({processId, applicationId}: Props): React.ReactElement => {

  const history = useGuardedHistory();
  const fetchProcesses = useGet<ProcessTO[]>(PROCESSES_URL);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [options, setOptions] = useState<NxSelectOption<number>[]>([]);
  const selectedProduct = options.find(option => option.value === processId)?.value;
  const {setProcessChanged, applicationParams, application} = useContext(LoanApplicationContext);

  const navigateToNewLoanApplication = (processId: number): void => {
    const newLoanCalculatorPath = buildApplicationStepPath(RoutePaths.CALCULATOR, processId, applicationId);
    history.push(newLoanCalculatorPath);
    setProcessChanged(true);
  };

  useEffect(() => {
    fetchProcesses(statusPublishedParam).then(processesTOs => {
      const processes = processesTOs
        .map(fromProcessTO);

      setProcesses(processes);
      const processOptions = processes.map(process => ({
        value: process.id,
        label: process.loanProduct.name
      }) as NxSelectOption<number>);

      setOptions(processOptions);
    });
  }, []);

  const onChange = (value): void => {
    if (isNil(value)) {
      return;
    }

    const process = processes.find(process => process.id == value);
    if (isNil(process)) {
      return;
    }

    navigateToNewLoanApplication(process.id);
  };

  return <div className={styles.form}>
    <div className={styles.label}>Change product:</div>
    <NxSelect className={styles.select}
              options={options}
              value={selectedProduct}
              onChange={onChange}
              label={'Product'}
              disabled={(applicationParams.isStepDone || !applicationParams.isEditable) && !!application?.id} />
  </div>;
};
