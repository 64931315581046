import {NxFormikDatePicker} from '@nextbank/ui-components';
import React, {useContext} from 'react';
import {ConfiguredField} from '../../../../../../shared/model/field.model';
import {StepContext} from '../loan-application-step/LoanApplicationStep';
import {FieldDisablementReason} from './field-disablement-reason.model';
import {ConfiguredFieldWrapper} from './ConfiguredFieldWrapper';

interface Props {
  field: ConfiguredField;
  label: React.ReactNode;
  name: string;
  disablementReason?: FieldDisablementReason;
}

export const ConfiguredFieldDatePicker = ({name, field, label, disablementReason}: Props): React.ReactElement => {

  const {isStepReadonly} = useContext(StepContext);

  return (
    <ConfiguredFieldWrapper enabled={field.enabled} disablementReason={disablementReason}>
      <NxFormikDatePicker disabled={isStepReadonly || !!disablementReason}
                          required={field.required}
                          name={`${name}.value`}
                          label={label} />
    </ConfiguredFieldWrapper>
  );
};

