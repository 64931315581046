import {Form} from 'formik';
import {FormikErrors} from 'formik/dist/types';
import isEmpty from 'lodash/isEmpty';
import React, {ReactElement, useContext} from 'react';
import {CustomerDataRequirementEntries} from '../../../../../../../shared/hooks/use-customer-data-requirements.hook';
import {CustomerType} from '../../../../../../../shared/model/customer-type.model';
import {Dictionary} from '../../../../../../../shared/model/dictionary.model';
import {
  CustomerDataPhase
} from '../../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import CustomerDataTabs from '../../../../../../shared/customer-data-tabs/CustomerDataTabs';
import Addresses from '../../addresses/Addresses';
import CorporateData from '../../applicant-data/corporate-data/CorporateData';
import IndividualData from '../../applicant-data/individual-data/IndividualData';
import CustomFields from '../../custom-fields/CustomFields';
import {CustomerDataFormFields} from '../../customer-data-form.model';
import {PrefixTrans} from '../../CustomerData';
import IdDocuments from '../../documents/IdDocuments';
import {CustomerDataApplicationDictionaries} from '../../hooks/use-customer-data-application-dictionaries.hook';
import Income from '../../income/Income';
import {ApplicantContext} from '../ApplicantContext';
import styles from './ApplicantDataForm.module.scss';

interface Props {
  values: CustomerDataFormFields;
  errors?: FormikErrors<CustomerDataFormFields>;
  /**
   * formikObjectPath: string representation of path in formik form for eg.: borrower.individualData.firstName.
   */
  formikFieldPath: string;
  config: CustomerDataPhase;
  customDictionaries?: Dictionary[];
  entries: CustomerDataApplicationDictionaries;
  requirementEntries: CustomerDataRequirementEntries;
  showTabs?: boolean;
}

export const ApplicantDataForm = (
  {
    values,
    errors,
    formikFieldPath,
    config,
    customDictionaries,
    showTabs,
    requirementEntries,
    entries
  }: Props
): ReactElement => {

  const {isCoreCustomer} = useContext(ApplicantContext);
  const individualDataError = !isEmpty(errors?.individualData);
  const corporateDataError = !isEmpty(errors?.corporateData);
  const idDocumentsError = !isEmpty(errors?.idDocuments);
  const addressesError = !isEmpty(errors?.addresses);
  const incomeError = !isEmpty(errors?.incomeSources);
  const customFieldsError = !isEmpty(errors?.customFieldValues);

  const applicantDataTab = values.type === CustomerType.CORPORATE ?
    {
      corporateDataTab: {
        error: corporateDataError,
        tabPanel: <CorporateData values={values.corporateData}
                                  formikFieldPath={formikFieldPath}
                                  entries={entries.corporateDataDictionaryEntries} />
      }
    } : {
      individualDataTab: {
        error: individualDataError,
        tabPanel: <IndividualData values={values.individualData}
                                  formikFieldPath={formikFieldPath}
                                  entries={entries.individualDataDictionaryEntries} />
      }
    };

  const documentsTab = {
    error: idDocumentsError,
    tabPanel: <IdDocuments requirement={config?.idDocumentRequirement}
                           documents={values.idDocuments}
                           formikFieldPath={formikFieldPath}
                           entries={{
                             country: entries.country,
                             idDocumentRequirement: requirementEntries.idDocumentRequirement
                           }} />
  };

  const addressesTab = {
    error: addressesError,
    tabPanel: <Addresses requirement={config?.addressRequirement}
                         addresses={values.addresses}
                         formikFieldPath={formikFieldPath}
                         entries={{
                           country: entries.country,
                           province: entries.province,
                           addressRequirementEntries: requirementEntries.addressRequirement
                         }} />
  };

  const incomeTab = {
    error: incomeError,
    tabPanel: <Income requirement={config?.incomeRequirement}
                      incomeSources={values.incomeSources}
                      formikFieldPath={formikFieldPath}
                      entries={{
                        natureOfWork: entries.natureOfWork,
                        natureType: entries.natureType,
                        businessType: entries.businessType,
                        incomeRequirementEntries: requirementEntries.incomeRequirement,
                        employerType: entries.employerType
                      }} />
  };

  const customFieldsTab = {
    error: customFieldsError,
    tabPanel: <CustomFields fieldGroups={config?.dynamicFieldGroups}
                            dictionaries={customDictionaries}
                            formikFieldPath={formikFieldPath}
                            values={values.customFieldValues} />
  };

  return (
    <div className={styles.container}>
      {
        isCoreCustomer && (individualDataError || incomeError || addressesError || idDocumentsError)
        && (
          <div className={styles.stepError}>
            <PrefixTrans>MISSING_CBS_DATA</PrefixTrans>
          </div>
        )
      }
      <Form>
        {
          showTabs &&
          <CustomerDataTabs tabs={{...applicantDataTab, documentsTab, addressesTab, incomeTab, customFieldsTab}}
                            className={styles.tabs} />
        }
      </Form>
    </div>
  );
};

