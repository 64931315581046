import axios from 'axios';
import {useEffect, useState} from 'react';
import {ABORT_ERROR_NAME} from '../http-service/http-error.model';
import HttpService from '../http-service/http.service';
import useCatchUnauthorizedError from './use-catch-unauthorized-error.hook';

/**
 * Helpful hook for getting data from API directly after component rendered.
 *
 * @param url - target url for API request.
 * @param mapFunction - function which should be used to map received data e.g. TO object to object.
 * It can be null or undefined, in case of response will be cast to T generic type.
 * @param shouldExecute - indicates when hook should perform request.
 * @param prams - Map of query parameters (url parameters), defaulted to empty if undefined.
 *
 * T - type of data returned by hook execution.
 * E - type of data returned by api call.
 *
 * @return data of type T (after mapping or casting) returned by api call.
 */

export default function useSingleQuery<T, E = T>(
    url: string,
    mapFunction?: ((arg: E) => T) | null,
    shouldExecute?: boolean,
    params: URLSearchParams = {} as URLSearchParams
  ): T | undefined {
  const [data, setData] = useState<T>();
  const catchUnauthorizedError = useCatchUnauthorizedError();

  useEffect(() => {
    if (shouldExecute === false) {
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    HttpService.get<E>(url, cancelTokenSource, {params})
      .then(response => setData(mapFunction ? mapFunction(response) : response as unknown as T))
      .catch(e => e.error?.errorCode === ABORT_ERROR_NAME ? Promise.resolve() : catchUnauthorizedError(e));

    return (): void => cancelTokenSource.cancel();
  }, [shouldExecute, url]);

  return data;
}
