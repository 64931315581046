import {NxSelectOption} from '@nextbank/ui-components';
import React from 'react';
import {Trans} from 'react-i18next';
import {SetFieldValue} from '../../../../../../shared/model/formik.model';
import {
  CorporateDataFormFieldsConfiguration
} from '../../../../../../shared/model/step-forms/corporate-data-form.model';
import {StringHelper} from '../../../../../../utils/string-helper';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {FieldDefaultCheckbox} from '../../../../../shared/field-default/FieldDefaultCheckbox';
import {FieldDefaultDatePicker} from '../../../../../shared/field-default/FieldDefaultDatePicker';
import {FieldDefaultInput} from '../../../../../shared/field-default/FieldDefaultInput';
import {FieldDefaultPhoneInput} from '../../../../../shared/field-default/FieldDefaultPhoneInput';
import {FieldDefaultSelect} from '../../../../../shared/field-default/FieldDefaultSelect';
import {YES_NO_SELECT_OPTIONS} from '../../../../../shared/form-bolean-option-values/YesNoSelectOptions';
import FormColumn from '../../../../../shared/form-column/FormColumn';
import Instruction from '../../../../../shared/instruction/Instruction';
import styles from './CorporateData.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.CUSTOMER_DATA.APPLICANT_DATA');

interface Props {
  values: CorporateDataFormFieldsConfiguration;
  setFieldValue: SetFieldValue;
  options: {
    businessType?: NxSelectOption[];
    riskLevel?: NxSelectOption[];
    dosriType?: NxSelectOption[];
    conglomerate?: NxSelectOption[];
    registrationCountry?: NxSelectOption[];
    relatedPartyTransaction?: NxSelectOption[];
  };
}

export default function CorporateData({values, setFieldValue, options}: Props): React.ReactElement {
  return (
    <>
      <Instruction defaultFieldsInstruction>
        <Trans>COMMON.FIELDS.DEFAULT_FIELDS_INSTRUCTION</Trans>
      </Instruction>
      <div className={styles.row}>
        <FormColumn header={<PrefixTrans>CORPORATE_DATA_LABEL</PrefixTrans>}>
          <FieldDefaultInput name='corporateData.businessName'
                             field={values.businessName}
                             label={values.businessName.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='corporateData.tradeName'
                             field={values.tradeName}
                             label={values.tradeName.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultSelect name='corporateData.businessTypeId'
                              field={values.businessTypeId}
                              label={values.businessTypeId.name}
                              setFieldValue={setFieldValue}
                              options={options.businessType} />
          <FieldDefaultSelect name='corporateData.conglomerateId'
                              field={values.conglomerateId}
                              label={values.conglomerateId.name}
                              setFieldValue={setFieldValue}
                              options={options.conglomerate} />
          <FieldDefaultInput name='corporateData.sourceOfFunds'
                             field={values.sourceOfFunds}
                             label={values.sourceOfFunds.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='corporateData.placeOfIncorporation'
                             field={values.placeOfIncorporation}
                             label={values.placeOfIncorporation.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultInput name='corporateData.registrationNumber'
                             field={values.registrationNumber}
                             label={values.registrationNumber.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultDatePicker name='corporateData.registrationDate'
                                  field={values.registrationDate}
                                  label={values.registrationDate.name}
                                  setFieldValue={setFieldValue} />
          <FieldDefaultSelect name='corporateData.registrationCountryId'
                              field={values.registrationCountryId}
                              label={values.registrationCountryId.name}
                              setFieldValue={setFieldValue}
                              options={options.registrationCountry} />
        </FormColumn>
        <FormColumn header={<PrefixTrans>CONTACT_DATA</PrefixTrans>}>
          <FieldDefaultInput name='corporateData.email'
                             field={values.email}
                             label={values.email.name}
                             setFieldValue={setFieldValue} />
          <FieldDefaultPhoneInput name='corporateData.phoneNumber'
                                  field={values.phoneNumber}
                                  label={values.phoneNumber.name}
                                  setFieldValue={setFieldValue} />
        </FormColumn>
      </div>
      <div className={styles.row}>
        <FormColumn header={<PrefixTrans>COMPLIANCE</PrefixTrans>}>
          <FieldDefaultSelect name='corporateData.riskLevelId'
                              field={values.riskLevelId}
                              label={values.riskLevelId.name}
                              setFieldValue={setFieldValue}
                              options={options.riskLevel} />
          <FieldDefaultSelect name='corporateData.dosri'
                              field={values.dosri}
                              label={values.dosri.name}
                              setFieldValue={setFieldValue}
                              options={YES_NO_SELECT_OPTIONS}
                              emptyOption={false} />
          {
            StringHelper.stringToBoolean(values.dosri.defaultValue) &&
            <FieldDefaultSelect name='corporateData.dosriTypeId'
                                field={values.dosriTypeId}
                                label={values.dosriTypeId.name}
                                setFieldValue={setFieldValue}
                                options={options.dosriType} />
          }
          <FieldDefaultCheckbox name='corporateData.picture' field={values.picture} setFieldValue={setFieldValue}>
            <PrefixTrans>CUSTOMER_PICTURE</PrefixTrans>
          </FieldDefaultCheckbox>
          <FieldDefaultCheckbox name='corporateData.signature' field={values.signature} setFieldValue={setFieldValue}>
            <PrefixTrans>CUSTOMER_SIGNATURE</PrefixTrans>
          </FieldDefaultCheckbox>
          <FieldDefaultSelect name='corporateData.relatedPartyTransactionId'
                              field={values.relatedPartyTransactionId}
                              label={values.relatedPartyTransactionId.name}
                              setFieldValue={setFieldValue}
                              options={options.relatedPartyTransaction}
                              emptyOption={false} />
          <FieldDefaultInput name='corporateData.relatedPartyTransactionDescription'
                             field={values.relatedPartyTransactionDescription}
                             label={values.relatedPartyTransactionDescription.name}
                             setFieldValue={setFieldValue} />
        </FormColumn>
      </div>
    </>
  );
}
