import clsx from 'clsx';
import {isNil} from 'lodash';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as BinIcon} from '../../../../assets/images/icon-bin.svg';
import {blobToBase64String} from '../../../../utils/file-upload-utils';
import IconButtonSwitch from '../../icon-button-switches/IconButtonSwitch';
import {FileTypes, FileUploadError, FileUploadErrorCode} from './file-upload.model';
import styles from './NxFileUpload.module.scss';

export const DEFAULT_MAXIMUM_FILE_SIZE = 4194304;
const TransPrefix = 'COMMON.FILE_UPLOAD';

const getFileExtensionName = (type: FileTypes): string => {
  switch (type) {
    case FileTypes.APPLICATION_PDF:
      return 'pdf';
    case FileTypes.IMAGE_JPG:
      return 'jpg';
    case FileTypes.IMAGE_PNG:
      return 'png';
    case FileTypes.CSV:
      return 'csv';
  }
};

export interface FileUploadProps {
  acceptedFileTypes?: FileTypes[];
  className?: string;
  disabled?: boolean;
  label?: React.ReactNode;
  maximumFileSize?: number;
  onBlur?: () => void;
  onChange: (file?: File) => Promise<void> | void;
  onChangeFailed?: (error: FileUploadError) => void;
  required?: boolean;
  showAcceptedFileTypesInfo?: boolean;
  value?: File;
  externalError?: string;
}

const NxFileUpload = (
  {
    acceptedFileTypes,
    className,
    disabled,
    label,
    onBlur,
    onChange,
    onChangeFailed,
    maximumFileSize = DEFAULT_MAXIMUM_FILE_SIZE,
    required = false,
    showAcceptedFileTypesInfo = true,
    value,
    externalError
  }: FileUploadProps): React.ReactElement => {

  const {t} = useTranslation();
  const [image, setImage] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (value) {
      // If value exists initial set value
      (async (): Promise<void> => {
        await setFileParameters(value);
      })();
    } else {
      // If value absent reset from
      setImage(undefined);
      setFileName(undefined);
    }
  }, [value]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0] ?? null;

    if (isNil(file)) {
      return;
    }

    if (file.size > maximumFileSize) {

      // Change bytes to MB / KB
      const maximumSize = maximumFileSize >= 1000000
        ? `${maximumFileSize / 1024 / 1024} MB`
        : `${maximumFileSize / 1024} KB`;

      handleFileAttachError({
        errorCode: FileUploadErrorCode.FILE_TOO_LARGE,
        message: t(`${TransPrefix}.ERROR.FILE_TOO_LARGE`, {maximumSize})
      });

      return;
    }

    handleFileAttached(file);
  };

  const handleFileAttached = async (file: File): Promise<void> => {
    setError(undefined);
    await setFileParameters(file);
    onChange(file);
  };

  const handleFileAttachError = (error: FileUploadError): void => {
    if (onChangeFailed) {
      onChangeFailed(error);
    }

    setError(error.message);
    console.error(error.message);
  };

  const setFileParameters = async (file: File): Promise<void> => {
    const imageString = await blobToBase64String(file);

    setImage(imageString);
    setFileName(file.name);
  };

  const removeFile = (): void => {
    setImage(undefined);
    setFileName(undefined);
    onChange(undefined);
  };

  const LabelWithAcceptedFilesInfo = (
    <div className={styles.label}>
      {label} {required && '*'}
      {
        showAcceptedFileTypesInfo && acceptedFileTypes &&
        <div className={styles.fileFormats}>
          <Trans values={{formats: acceptedFileTypes.map(getFileExtensionName).join(', ')}}>
            {`${TransPrefix}.SUPPORTED_FORMATS`}
          </Trans>
        </div>
      }
    </div>
  );

  const spanTag = <span className={clsx(styles.selectFile, {[styles.selectFile_disabled]: disabled})} />;
  const fileUploadClassName = clsx(
    styles.fileUpload,
    {
      [styles.fileUpload_error]: !isNil(error) || !isNil(externalError),
      [styles.fileUpload_disabled]: disabled
    });

  return (
    <div className={clsx(styles.wrapper, className)}>
      {label && LabelWithAcceptedFilesInfo}
      <div className={styles.fileUploadWrapper}>
        <div className={fileUploadClassName}>
          <div>
            <Trans components={{spanTag}}>
              {`${TransPrefix}.DROP_FILE_HERE_OR_SELECT`}
            </Trans>
          </div>
          <input accept={acceptedFileTypes?.toString() ?? ''}
                 className={clsx(styles.input, {[styles.input_disabled]: disabled})}
                 onBlur={onBlur}
                 onChange={onInputChange}
                 disabled={disabled}
                 multiple={false}
                 type='file' />
        </div>
        {
          error && <div className={styles.error}>{error}</div>
        }
        {
          externalError && <div className={styles.error}>{externalError}</div>
        }
        {
          image && fileName && (
            <div className={styles.row}>
              <div className={styles.imageWrapper}>
                <img className={styles.image} src={image} />
              </div>
              {fileName}
              <IconButtonSwitch className={styles.removeFile}
                                ariaLabel={'remove file'}
                                onClick={removeFile}
                                disabled={disabled}
                                icon={<BinIcon />}
                                bordered={false} />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default NxFileUpload;
