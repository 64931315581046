import {NxFormikCashInput, NxFormikInput, NxFormikSelect} from '@nextbank/ui-components';
import {useFormikContext} from 'formik';
import React, {ReactElement} from 'react';
import {DictionaryEntryRequirement} from '../../../../../../shared/model/dictionary-requirement.model';
import {DictionaryEntry} from '../../../../../../shared/model/dictionary.model';
import {TransHelper} from '../../../../../../utils/trans-helper';
import {
  CustomerDataConfigFormFields
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-form.model';
import FormColumn from '../../../../../shared/form-column/FormColumn';
import FormGroup from '../../../../../shared/form-column/FormGroup';
import {ConfiguredFieldWrapper} from '../../shared/configured-fields/ConfiguredFieldWrapper';
import {FieldDisablementReason} from '../../shared/configured-fields/field-disablement-reason.model';
import {Address} from '../addresses/address.model';
import RequirementFormHeader from '../shared/RequirementFormHeader';
import {IncomeSource} from './income-source.model';
import styles from './IncomeSourceFrom.module.scss';
import IncomeSourceTypeInputs, {IncomeSourceTypeInputsEntries} from './type-inputs/IncomeSourceTypeInputs';
import { SelectHelper } from '../../../../../../utils/select-helper';

export interface IncomeSourceFromEntries extends IncomeSourceTypeInputsEntries {
  incomeRequirementEntries?: DictionaryEntry[];
  employerType?: DictionaryEntry[];
}

interface Props {
  /**
   * parentFieldPath: string representation of path in object for eg.: borrower.incomeSources[1].number.
   */
  formPath: string;
  entry: DictionaryEntryRequirement;
  entries: IncomeSourceFromEntries;
  deleteFunction: (typeId: number) => void;
  disablementReason?: FieldDisablementReason;
  disabled?: boolean;
}

const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_APPLICATIONS.CUSTOMER_DATA.INCOME_SOURCES');

export default function IncomeSourceForm(
  {entry, entries, formPath, deleteFunction, disablementReason, disabled}: Props
): ReactElement {

  // Variable defines field requirement. All form fields using this variable are required if requirement changed
  // check validation schema too
  const required = true;
  const getName = (key: keyof IncomeSource): string => `${formPath}.${key}`;
  const birthCountryOptions = SelectHelper.mapToNumberOptions(entries.employerType);

  const Header =
    <RequirementFormHeader disabled={disabled}
                           entry={entry}
                           entries={entries.incomeRequirementEntries}
                           deleteFunction={deleteFunction} />;

  return (
    <FormColumn>
      <FormGroup className={styles.inputs} header={Header}>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikCashInput label={<PrefixTrans>INCOME</PrefixTrans>}
                             name={getName('income')}
                             disabled={disabled}
                             required={required} />
        </ConfiguredFieldWrapper>
        <IncomeSourceTypeInputs formPath={formPath}
                                entries={entries}
                                disablementReason={disablementReason}
                                disabled={disabled} />
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikInput label={<PrefixTrans>COMPANY_NAME</PrefixTrans>}
                         name={getName('companyName')}
                         disabled={disabled}
                         required={false} />
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikSelect<number> label={<PrefixTrans>EMPLOYER_TYPE</PrefixTrans>}
                                  name={getName('employerTypeId')}
                                  options={birthCountryOptions}
                                  disabled={disabled}
                                  emptyOption />
        </ConfiguredFieldWrapper>
        <ConfiguredFieldWrapper disablementReason={disablementReason}>
          <NxFormikInput label={<PrefixTrans>EMPLOYER_NAME</PrefixTrans>}
                         name={getName('employerName')}
                         disabled={disabled}
                         required={false} />
        </ConfiguredFieldWrapper>
      </FormGroup>
    </FormColumn>
  );
}
