import isNil from 'lodash/isNil';
import {FieldRecords, getConfiguredFields} from '../../../../../../utils/step-form-utils/configured-fields-utils';
import {getCompanyDataInitFields} from '../../../../../../utils/step-form-utils/corporate-data-init-fields';
import {getIndividualDataInitFields} from '../../../../../../utils/step-form-utils/individual-data-init-fields';
import {
  CustomerDataPhase
} from '../../../../../loan-configurations/loan-configuration/steps/customer-data/customer-data-phase.model';
import useApplicantDataDocuments from '../applicant-data/individual-data/use-individual-data-files.hook';
import useCustomFieldInitValues from '../custom-fields/use-custom-field-init-values';
import {CustomerDataFormHelper} from '../customer-data-form-helper';
import {
  Applicant,
  CorporateDataFormFields,
  CustomerDataFormFields,
  IndividualDataFormFields
} from '../customer-data-form.model';
import useIdDocuments from '../documents/use-id-document-files';

interface ApplicantsDataState {
  applicantsDataLoaded: boolean;
  applicantsData?: CustomerDataFormFields[];
}

const {
  getAddresses,
  getIncomeSources,
  overwriteIndividualDataBySavedValues,
  overwriteCorporateDataBySavedValues
} = CustomerDataFormHelper;

export default function useApplicantsData(applicants?: Applicant<number>[],
                                          config?: CustomerDataPhase): ApplicantsDataState {
  const {idDocuments, areIdDocumentsLoaded} = useIdDocuments(applicants ?? [], config);
  const {applicantDataDocuments: individualDataDocuments, areApplicantDataDocumentsLoaded: areIndividualDataDocumentsLoaded} = useApplicantDataDocuments(false, applicants);
  const {applicantDataDocuments: corporateDataDocuments, areApplicantDataDocumentsLoaded: areCorporateDataDocumentsLoaded} = useApplicantDataDocuments(true, applicants);
  const {customFieldInitValues, areCustomFieldValuesLoaded} = useCustomFieldInitValues(config, applicants);

  const getFormInitialValues = (config: CustomerDataPhase, applicant: Applicant<number>): CustomerDataFormFields => {
    const individualDataFieldsConfig = getIndividualDataInitFields(config) as unknown as FieldRecords;
    const individualData = getConfiguredFields(individualDataFieldsConfig) as unknown as IndividualDataFormFields;
    const corporateDataFieldsConfig = getCompanyDataInitFields(config) as unknown as FieldRecords;
    const corporateData = getConfiguredFields(corporateDataFieldsConfig) as unknown as CorporateDataFormFields;

    // TODO default values are provided on client null value fields
    overwriteIndividualDataBySavedValues(individualData, applicant?.individualData);
    overwriteCorporateDataBySavedValues(corporateData, applicant?.corporateData);

    let initialValues = {
      // TODO It should be a uuid key form generateKey function. Unfortunately due to re-rendering this object its
      // recreated and key is regenerated. Applicant ID can be used as a key as ID comes form DB and it is unique
      key: applicant.id.toString(),
      id: applicant.id,
      individualDataId: applicant.individualData?.id,
      corporateDataId: applicant.corporateData?.id,
      sourceNumber: applicant.sourceNumber,
      sourceId: applicant.sourceId,
      isCoreCustomer: !isNil(applicant?.sourceNumber),
      type: applicant.type,
      relationshipType: applicant.relationshipType,
      individualData,
      corporateData,
      addresses: getAddresses(config, applicant),
      idDocuments,
      incomeSources: getIncomeSources(config, applicant),
      customFieldValues: customFieldInitValues
    } as CustomerDataFormFields;

    const individualDataFiles = individualDataDocuments.filter(docs => docs.applicantId === applicant.id)[0];
    if (!isNil(individualDataFiles)) {
      const {picture: individualDataPicture, signature: individualDataSignature} = individualDataFiles;
      initialValues = {
        ...initialValues,
        individualData: {
          ...individualData,
          picture: {...individualData.picture, ...individualDataPicture ? {value: individualDataPicture} : {}},
          signature: {...individualData.signature, ...individualDataSignature ? {value: individualDataSignature} : {}}
        },
      };
    }

    const corporateDataFiles = corporateDataDocuments.filter(docs => docs.applicantId === applicant.id)[0];

    if (!isNil(corporateDataFiles)) {
      const {picture: corporateDataPicture, signature: corporateDataSignature} = corporateDataFiles;
      initialValues = {
        ...initialValues,
        corporateData: {
          ...corporateData,
          picture: {...corporateData.picture, ...corporateDataPicture ? {value: corporateDataPicture} : {}},
          signature: {...corporateData.signature, ...corporateDataSignature ? {value: corporateDataSignature} : {}}
        }
      };
    }
    return initialValues;
  };

  const initialDataLoaded = areIdDocumentsLoaded && areIndividualDataDocumentsLoaded && areCustomFieldValuesLoaded && areCorporateDataDocumentsLoaded;
  const applicantsDataLoaded = !isNil(config) && initialDataLoaded;

  return {
    applicantsDataLoaded,
    applicantsData: applicantsDataLoaded
      ? applicants?.map(applicant => getFormInitialValues(config!, applicant))
      : undefined
  };
}
