import {NxSelectOption} from '@nextbank/ui-components';
import {ApiHelper} from '../../utils/api-helper';
import {SelectHelper} from '../../utils/select-helper';
import {DictionaryEntry} from '../model/dictionary.model';
import useSingleQuery from './use-single-query.hook';

const {getDictionaryByCodeUrl} = ApiHelper;

export enum DictionaryCode {
  COUNTRY = 'SYS_COUNTRY',
  PROVINCE = 'SYS_PROVINCE',
  NATURE_OF_WORK_OR_BUSINESS = 'SYS_NATURE_OF_WORK_BUSINESS',
  NATURE_TYPE = 'CBS_NOWB_TYPE',
  BUSINESS_TYPE = 'CBS_NOWB_BUSINESS_TYPE',
  EMPLOYER_TYPE = 'SYS_EMPLOYER_TYPE'
}

export default function useOptionsFromDictionaryByCode(dictionaryCode?: DictionaryCode): NxSelectOption<number>[] | undefined {

  const urlParams = new URLSearchParams(`dictionaryCode=${dictionaryCode}`);

  return useSingleQuery<NxSelectOption<number>[], DictionaryEntry[]>(
    getDictionaryByCodeUrl(),
    SelectHelper.mapToNumberOptions,
    true,
    urlParams
  );
}
