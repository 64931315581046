import {CustomerType} from '../../../../../shared/model/customer-type.model';
import {ConfiguredField} from '../../../../../shared/model/field.model';
import {FileBasicData, NxFile} from '../../../../../shared/model/nx-file.model';
import {CorporateDataFormFieldsConfiguration} from '../../../../../shared/model/step-forms/corporate-data-form.model';
import {IndividualDataFormFieldsConfiguration} from '../../../../../shared/model/step-forms/individual-data-form.model';
import {OptionalValidationForm} from '../../../../../shared/model/step-forms/optional-validation-form.model';
import {StepPayloadType} from '../shared/step-payload.model';
import {Address, AddressTO} from './addresses/address.model';
import {CustomFieldValue, CustomFieldValueTO} from './custom-fields/custom-field.model';
import {IdDocument, IdDocumentTO} from './documents/id-document.model';
import {IncomeSource, IncomeSourceTO} from './income/income-source.model';

export interface IndividualCustomerProfilingPhasePayload {
  type: StepPayloadType.CUSTOMER_DATA;
  borrower?: Applicant;
  coBorrowers?: Applicant[];
  coMakers?: Applicant[];
}

export interface IndividualDataFormFields extends IndividualDataFormFieldsConfiguration<ConfiguredField, ConfiguredField<NxFile>> {
}

export interface CorporateDataFormFields extends CorporateDataFormFieldsConfiguration<ConfiguredField, ConfiguredField<NxFile>> {
}

// TODO name?? suggestion ApplicantDataFormField
export interface CustomerDataFormFields extends OptionalValidationForm {
  key: string;
  id?: number;
  individualDataId?: number;
  corporateDataId?: number;
  sourceId?: number;
  sourceNumber?: string;
  isCoreCustomer: boolean;
  type: CustomerType;
  relationshipType: ApplicationRelationshipType;
  individualData: IndividualDataFormFields;
  corporateData: CorporateDataFormFields;
  addresses: Address[];
  idDocuments: IdDocument[];
  incomeSources: IncomeSource[],
  customFieldValues: (CustomFieldValue | CustomFieldValue<NxFile>)[];
}

// This model needs to be mapped on formikFieldPath field in 'BorrowerData', 'Related people' components and descendants.
export interface CustomersDataFormFields extends OptionalValidationForm{
  borrower?: CustomerDataFormFields
  coBorrowers?: CustomerDataFormFields[],
  coMakers?: CustomerDataFormFields[]
}


export interface IndividualDataTO extends IndividualDataFormFieldsConfiguration<string, FileBasicData> {
  id?: number;
  applicantId?: number;
}

export interface CorporateDataTO extends CorporateDataFormFieldsConfiguration<string, FileBasicData> {
  id?: number;
  applicantId?: number;
}

export interface Applicant<ID = number | undefined> {
  id: ID;
  sourceId?: number;
  sourceNumber?: string;
  type: CustomerType;
  relationshipType: ApplicationRelationshipType;
  individualData?: IndividualDataTO;
  corporateData?: CorporateDataTO;
  addresses: AddressTO[];
  idDocuments: IdDocumentTO[];
  incomeSources: IncomeSourceTO[];
  customFieldValues: CustomFieldValueTO[];
}

export enum ApplicationRelationshipType {
  BORROWER = 'BORROWER',
  CO_BORROWER = 'CO_BORROWER',
  CO_MAKER = 'CO_MAKER'
}

export interface EntryRequirementValue {
  typeId: number;
}
